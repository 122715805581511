import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Project, ProjectsQuery } from 'timeghost-api';

@Pipe({
  name: 'projectRef',
  pure: false,
})
export class ProjectRefPipe implements PipeTransform {
  constructor(private projectsQuery: ProjectsQuery) {}
  transform(id: string, async: boolean = false): Observable<Project> | Project {
    if (!id) return null;
    if (async) return this.projectsQuery.selectEntity(id);
    return this.projectsQuery.getEntity(id);
  }
}
