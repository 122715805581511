import { Pipe, PipeTransform } from '@angular/core';
import { Client, ClientsQuery } from 'timeghost-api';

@Pipe({
  name: 'clientRef',
  pure: false,
})
export class ClientRefPipe implements PipeTransform {
  constructor(private clientsQuery: ClientsQuery) {}
  transform(id: string): Client {
    if (!id) return null;
    return this.clientsQuery.getEntity(id);
  }
}
